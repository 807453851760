.App {
  display: flex;
  width: 100%;
  align-items: stretch;
}

@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";

body {
  font-family: 'Poppins', sans-serif;
  background: #fafafa;
}


a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

/* ---------------------------------------------------
    SIDEBAR 
----------------------------------------------------- */
.sidebar {
  min-width: 250px;
  max-width: 250px;
  background: #7386D5;
  color: #fff;
  margin-left: -250px;
  transition: all 0.5s;
}

.sidebar.is-open {
  margin-left: 0;
  transition: .5s;
}


.sidebar-header {
  background: #6d7fcc;
}

.sidebar-header h3 {
  color: #fff;
  padding: 1em;
}

.sidebar ul p {
  color: #fff;
  padding: 10px;
}

.menu-open {
  background: #6d7fcc;
}

.nav-item:hover {
  color: #7386D5;
  background: #fff;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav a:hover {
  color: rgba(0, 0, 0, .7);
}

.navbar-light .navbar-nav a.active {
  color: green;
}

.navbar-light .navbar-nav a {
  color: rgba(0, 0, 0, .5);
}

.navbar-expand-md .navbar-nav a {
  padding-right: .5rem;
  padding-left: .5rem;
}

.nav-item a {
  color: #fff;
  display: block;
  padding: .5rem 1rem;
}

.nav-tabs .nav-item a {
  color: #7386D5;
  background: #fff;
}

.nav-tabs .nav-link {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link:hover {
  font-style: italic;
  cursor: pointer;
}

.nav-item a.dropdown-toggle {
  color: rgba(0, 0, 0, .5);
  font-size: 1.1em;
}

.nav-item a.dropdown-toggle:hover,
.pl-4.nav-item a:hover {
  color: #7386D5;
  background: #fff;
}

.pl-4.nav-item {
  margin-left: 10px;
}

.items-menu {
  color: #fff;
  background: #6d7fcc;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%
}

.sidebar-header>a {
  position: relative;
  float: right;
  margin: .5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}

/* ---------------------------------------------------
    CONTENT 
----------------------------------------------------- */
.content {
  padding: 20px;
  margin-left: 0;
  height: 100vh;
}

@media only screen and (max-width: 500px) {
  body {
    overflow: hidden;
  }

  .content.is-open {
    margin-left: 100%;
  }

  .sidebar.is-open {
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    transition: all 0.5s, height 0s;
  }

  .sidebar.is-open>.sidebar-header a {
    display: unset;
  }

  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }
}

/* LOADING OVERLAY SPINNER */
.loadingSpinner ._loading_overlay_overlay {
  position: fixed;
}