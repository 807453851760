.item-list-box {
    margin-bottom: 1em;
    border-radius: 10px;
}

.item-list-box div div.col-sm-2 {
    margin: inherit;
    padding-left: 2em;
    border-right: solid;
}

