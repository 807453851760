nav.navbar {
    margin-top: -20px;
    margin-left: -20px;
    margin-right: -20px;
  }

.user-avatar {
    width: 28px;
    height: 28px;
}


li a.dropdown-toggle::after {
    left: auto;
}
