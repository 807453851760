.card-img {
    height: 110px;
    opacity: 0.3;
}

.card-body {
    z-index: 750;
}

.edit-overlay {
    float: right;
    margin-top: -10px;
}

.edit-overlay svg {
    margin: -9px -5px -5px -5px;
}

.edit-avatar-icon {
    background-color: indigo;
    margin-left: -14px;
    margin-top: -35px;
}