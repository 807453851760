.active.nav-link {
    background-color: blue !important;
    color: white !important;
}

.handle {
    cursor: move;
}

.item-list-box:first-child {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.item-list-box:last-child {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.item-list-box {
    padding: 1em;
}

.item-list-box div div {
    margin: auto;
}

.item-list-box div div h5, .item-list-box div div p {
    margin: 0;
}

.staff-member-box {
    padding: 0.5em;
    border-radius: 20px;
    margin-bottom: 5px;
    background: black !important;
    color: #f8f9fa;
}
.staff-member-box div div {
    margin: auto;
}

.staff-member-box div div h5, .staff-member-box div div p {
    margin: 0;
}

.staff-member-box div div p span {
    font-size: 0.7em;
}

.staff-member-box .btn-group {
    float: right;
    margin-right: 5px;
}

.staff-member-box .user-avatar-small {
    width: 30px;
    border-radius: 5px;
}
